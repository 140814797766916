import React, { Component } from "react";
import LanguageService from "../../services/language-api-service";
import LearningContext from "../../contexts/LearningContext";
import AnswerFeedback from "../../components/AnswerFeedback/AnswerFeedback";
import "./LearningRoute.css";

class LearningRoute extends Component {
  // only change context, not change state

  static contextType = LearningContext;

  /* calls api service, then setting context state */
  handleSubmit(guess) {
    LanguageService.submitGuess(guess).then((guessRes) => {
      this.context.setPrevWord(this.context.nextWord);
      this.context.clearError();
      this.context.setTotalScore(guessRes.totalScore);
      this.context.setWordCorrectCount(guessRes.wordCorrectCount);
      this.context.setWordIncorrectCount(guessRes.wordIncorrectCount);
      this.context.setNextWord(guessRes.nextWord);
      this.context.setAnswer(guessRes.answer);
      this.context.setGuess(guess);
      this.context.setIsCorrect(guessRes.isCorrect);
      this.context.setIsResultDisplayed(true);
    });
  }

  componentDidMount() {
    LanguageService.getHead() // only call once, used to set head of list aka first word asked
      .then((data) => {
        if (!data) {
          console.error(data);
          throw new Error(
            "Oh no! Something went wrong with getting next word."
          );
        }
        this.context.setNextWord(data.nextWord);
        this.context.setTotalScore(data.totalScore);
        this.context.setWordCorrectCount(data.wordCorrectCount);
        this.context.setWordIncorrectCount(data.wordIncorrectCount);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    return (
      <div class="w-screen h-screen flex justify-center items-center">
        <div class="container mx-auto max-w-sm w-full p-4 sm:w-1/2">
            {!this.context.isResultDisplayed ? (
          <div class="card flex flex-col justify-center p-10 bg-white rounded-lg shadow-2xl">
    
            <div class="prod-title">
              <p class="uppercase text-sm text-gray-400">
                Cal e a maneira correcta de dicir en galego ...?
              </p>
              <div class="text-center mt-6 mb-10">
                <p class="text-2xl uppercase text-gray-900 font-bold">
                  {this.context.nextWord}
                </p>
              </div>
            </div>
            <form
              htmlFor="guessForm"
              id="submit-form"
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit(e.target.guessForm.value.toLowerCase());
              }}
            >
              <div class="mb-6">
                <input
                  type="text"
                  name="guessForm"
                  class="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                  id="learn-guess-input"
                  required
                ></input>
              </div>
              <div class="text-center mt-6 mb-10">
                <button
                  type="submit"
                  class="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                >
                  Enviar
                </button>
              </div>
            </form>
            <div class="mt-4  flex flex-row flex-wrap md:flex-nowrap justify-between items-baseline">
              <ul>
                <li>Puntuación total: {this.context.totalScore}</li>

                <li>Acertada {this.context.wordCorrectCount} veces.</li>

                <li>Errada {this.context.wordIncorrectCount} veces.</li>
              </ul>
            </div>
          </div> ): (
              <div class="card flex flex-col justify-center p-10 bg-white rounded-lg shadow-2xl">
              <AnswerFeedback />
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default LearningRoute;
