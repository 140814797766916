import React, { Component } from "react";
import { Input, Label } from "../Form/Form";
import { Link } from "react-router-dom";
import AuthApiService from "../../services/auth-api-service";
import UserContext from "../../contexts/UserContext";
import Button from "../Button/Button";
import "./LoginForm.css";

class LoginForm extends Component {
  static defaultProps = {
    onLoginSuccess: () => {},
  };

  static contextType = UserContext;

  state = { error: null };

  firstInput = React.createRef();

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { username, password } = ev.target;

    this.setState({ error: null });

    AuthApiService.postLogin({
      username: username.value.toLowerCase(),
      password: password.value.toLowerCase(),
    })
      .then((res) => {
        username.value = "";
        password.value = "";
        this.context.processLogin(res.authToken);
        this.props.onLoginSuccess();
      })
      .catch((res) => {
        this.setState({ error: res.error });
      });
  };

  componentDidMount() {
    this.firstInput.current.focus();
  }

  render() {
    const { error } = this.state;
    return (
        <form className="LoginForm" onSubmit={this.handleSubmit}>
          <div>
            <h1 class="text-3xl font-bold text-center mb-4 cursor-pointer">
              Iniciar sesión
            </h1>
          </div>
          <div role="alert" className="text-red-600">
            {error && <p>{error}</p>}
          </div>
          <div class="mb-6">
            <Label
              htmlFor="login-username-input"
              className="block text-gray-800 font-bold"
            >
              Nome de usuario:
            </Label>
            <Input
              className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
              ref={this.firstInput}
              id="login-username-input"
              name="username"
              required
            />
          </div>

          <div class="mb-6">
            <Label
              class="block text-gray-800 font-bold"
              htmlFor="login-password-input"
            >
              Contrasinal
            </Label>
            <Input
              className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
              id="login-password-input"
              name="password"
              type="password"
              required
            />
          </div>

          <div class="text-center mt-6 mb-10">
            <Button
              className="cursor-pointer py-2 px-4 block mt-6 bg-indigo-500 text-white font-bold w-full text-center rounded"
              type="submit"
            >
              Login
            </Button>
            <p class="mt-4 text-sm">
              Non tes unha conta?{" "}
              <Link to="/register" className="underline cursor-pointer">
                Rexístrate
              </Link>
            </p>
          </div>
        </form>
    );
  }
}

export default LoginForm;
