import React from "react";
import { Link } from "react-router-dom";
import Word from "../Word/Word";
import LanguageApiService from "../../services/language-api-service";
import LanguageContext from "../../contexts/LanguageContext";
import Button from "../Button/Button";

import "./WordList.css";

class WordList extends React.Component {
  static contextType = LanguageContext;

  componentDidMount() {
    LanguageApiService.getWords().then((res) => {
      this.context.setLanguage(res.language);
      this.context.setWords(res.words);
    });
  }

  renderWordList() {
    const words = this.context.words.map((word, i) => <Word word={word} />);
    return (
      <div class="w-full rounded overflow-hidden flex flex-col p-6 md:py-8 lg:py-12 xl:py-16 md:px-8 lg:px-12 xl:px-20">
        <div
          class="overflow-x-auto overflow-y-auto relative"
          style={{ height: "405px" }}
        >
          <table class="w-full table text-gray-400 border-separate space-y-6 text-sm">
            <tbody class="">{words}</tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div class="text-3xl font-bold mb-3">Practicar!</div>
        <div class="text-md font-semibold text-gray-500 mb-6">
          {this.context.language.name}
        </div>

        <div class="flex justify-between mb-8">
          <div class="flex items-center space-x-3">
            <div class="font-bold text-xl">
              • Respostas correctas: {this.context.language.total_score}
            </div>
          </div>
          <Button className="border-2 border-black rounded-xl px-5 font-semibold bg-yellow-300">
            <Link to="/learn">Comezar</Link>
          </Button>
        </div>

        <div class="w-full h-full overflow-auto  bg-white" id="journal-scroll">
          {this.renderWordList()}
        </div>
      </div>
    );
  }
}

export default WordList;
