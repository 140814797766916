import React from "react";
import "./Word.css";

class Word extends React.Component {
  render() {
    return (
      <tr>
        <td class="p-3">
          <div>{this.props.word.original}</div>
        </td>
        <td class="p-3">
          <div class="leading-5">
              Acertos:{" "}
            <span class="text-green-900">{this.props.word.correct_count}</span> 
          </div>
        </td>
        <td class="p-3">
          <div class="leading-5 text-red-900">
            Fallos:{" "}
            <span class="text-red-900">{this.props.word.incorrect_count}</span> 
          </div>
        </td>
      </tr>
    );
  }
}

export default Word;
