import React from "react";
import Button from "../Button/Button";
import LearningContext from "../../contexts/LearningContext";
import "./AnswerFeedback.css";

class AnswerFeedback extends React.Component {
  static contextType = LearningContext;

  handleClick = () => {
    this.context.setIsResultDisplayed(false);
  };

  renderMessage = () => {
    if (this.context.isCorrect) {
      return (
        <div class="prod-title">
          <div class="text-center mt-6 mb-10">
            <p class="text-2xl uppercase text-gray-900 font-bold">
              Correcto! :D
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div class="prod-title">
            <div class="text-center mt-6 mb-10">
              <p class="text-2xl uppercase text-gray-900 font-bold">
                Bo intento, pero non é correcto :(
              </p>
            </div>
          </div>
          <p>
            A resposta correcta para{" "}
            <span en="gl">{this.context.prevWord}</span> era{" "}
            <strong>{this.context.answer}</strong> e ti respondiches{" "}
            <strong>{this.context.guess}</strong>!
          </p>
        </div>
      );
    }
  };

  render() {
    return (
      <section id="feedback">
        <div className="DisplayFeedback">
          {this.renderMessage()}
          <div class="text-center mt-6 mb-10">
            <Button
              className="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
              onClick={this.handleClick}
            >
              Continuar
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default AnswerFeedback;
