import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import WordList from "../../components/WordList/WordList";

class DashboardRoute extends Component {
  static defaultProps = {
    location: {},
    history: {
      push: () => {},
    },
  };

  startLearning = () => {
    const { history } = this.props;
    history.push("learn");
  };

  render() {
    return (
      <div class="min-h-screen flex justify-center items-center">
        <div class="container  bg-white  mx-auto py-6 px-4">
          <WordList startLearning={this.startLearning} />
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardRoute);
