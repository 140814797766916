import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Input, Required, Label } from "../Form/Form";
import AuthApiService from "../../services/auth-api-service";
import Button from "../Button/Button";
import "./RegistrationForm.css";

class RegistrationForm extends Component {
  static defaultProps = {
    onRegistrationSuccess: () => {},
  };

  state = { error: null };

  firstInput = React.createRef();

  handleSubmit = (ev) => {
    ev.preventDefault();
    const { name, username, password } = ev.target;
    AuthApiService.postUser({
      name: name.value,
      username: username.value.toLowerCase(),
      password: password.value.toLowerCase(),
    })
      .then((user) => {
        name.value = "";
        username.value = "";
        password.value = "";
        this.props.onRegistrationSuccess();
      })
      .catch((res) => {
        this.setState({ error: res.error });
      });
  };

  componentDidMount() {
    this.firstInput.current.focus();
  }

  render() {
    const { error } = this.state;
    return (
      <form className="register-form" onSubmit={this.handleSubmit}>
        <div>
          <h1 class="text-3xl font-bold text-center mb-4 cursor-pointer">
            Crear conta
          </h1>
          <p class="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer">
            Aprende vocabulario en galego coa técnica do aprendizaxe espaciado
          </p>
        </div>
        <div role="alert" className="text-red-600">
          {error && <p>{error}</p>}
        </div>
        <div class="mb-6">
          <Label
            className="block text-gray-800 font-bold"
            htmlFor="registration-name-input"
          >
            Nome
            <Required />
          </Label>
          <Input
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
            ref={this.firstInput}
            id="registration-name-input"
            name="name"
            required
          />
        </div>
        <div class="mb-6">
          <Label
            className="block text-gray-800 font-bold"
            htmlFor="registration-username-input"
          >
            Nome de usuario
            <Required />
          </Label>
          <Input
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
            id="registration-username-input"
            name="username"
            required
          />
        </div>
        <div class="mb-6">
          <Label
            className="block text-gray-800 font-bold"
            htmlFor="registration-password-input"
          >
            Contrasinal
            <Required />
          </Label>
          <Input
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
            id="registration-password-input"
            name="password"
            type="password"
            required
          />
        </div>
        <div class="text-center mt-6 mb-10">
          <Button
            className="cursor-pointer py-2 px-4 block mt-6 bg-indigo-500 text-white font-bold w-full text-center rounded"
            type="submit"
          >
            Rexistrarse
          </Button>
          <p class="mt-4 text-sm">
            Xa tes unha conta?{" "}
            <Link to="/login" className="underline cursor-pointer">
              Inicia sesión
            </Link>
          </p>
        </div>
      </form>
    );
  }
}

export default RegistrationForm;
