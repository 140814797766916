import React, { Component } from "react";
import { Link } from "react-router-dom";
import TokenService from "../../services/token-service";
import UserContext from "../../contexts/UserContext";
import "./Header.css";

class Header extends Component {
  static contextType = UserContext;

  handleLogoutClick = () => {
    this.context.processLogout();
  };

  renderLogoutLink() {
    return (
      <div>
        <Link
          className="text-gray-800 text-sm"
          onClick={this.handleLogoutClick}
          to="/login"
        >
          Sair
        </Link>
      </div>
    );
  }

  renderLoginLink() {
    return (
      <div>

      </div>
    );
  }

  render() {
    return (
      <header role="banner" class="w-full text-gray-700 bg-white h-16 fixed top-0 animated z-40">
        <nav>
          <div class="">
            <div class="flex justify-between h-16 px-10 shadow items-center">
              <div class="flex items-center space-x-8">
                <h1 class="text-xl lg:text-2xl font-bold cursor-pointer">
                  <Link to="/" className="app-title">
                    Aprendizaxe espaciado
                  </Link>
                </h1>
              </div>
              <div class="flex space-x-4 items-center">
                {TokenService.hasAuthToken()
                  ? this.renderLogoutLink()
                  : this.renderLoginLink()}
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
